export function serializeImage(obj, shouldNotHaveAlt) {
    if (!obj) {
        return null;
    }

    if (!obj?.file) {
        const defaultImageLoc = '/wt/static/pipit/assets/img/default-list-image.jpg';

        return {
            src: defaultImageLoc,
            alt: '',
            width: 900,
            height: 1200,
            srcSet: `${defaultImageLoc} 480w, ${defaultImageLoc} 768w, ${defaultImageLoc} 1024w`,
        };
    }

    const alt = obj.altText || obj.title || undefined;
    const credits = obj.credits || undefined;
    const caption = obj.caption || undefined;
    const width = obj.width || undefined;
    const height = obj.height || undefined;
    const title = obj.title || undefined;
    const focal = obj.focal || undefined;

    return {
        src: obj.url || obj.src,
        alt: shouldNotHaveAlt ? alt : '',
        credits: credits,
        width: width,
        height: height,
        title: title,
        focal: focal,
        caption: caption
    };
}
